import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HEADER_SKIP_ERROR_INTERCEPTOR } from 'src/app/error/error.headers';
import { Pageable } from 'src/app/util/pageable-request';
import { environment } from 'src/environments/environment';

import { HistoricoIndicadores } from './model/historico-indicadores.models';
import { IndicadorAlerta } from './model/indicador-alerta';

@Injectable({
  providedIn: 'root'
})
export class ParametroRiscoService {

  resourceUrl = environment.serviceGestaoUrl;
  resourceAnalise = environment.serviceAnaliseUrl

  private construirParametros(page: any): any {
    let params = new HttpParams();
    params = params = params.set('page', page.page ? page.page : '');
    params = params = params.set('size', page.size ? page.size : '');
    params = params = params.set('sort', page.sort ? page.sort : '');
    return params;
  }

  constructor(private http: HttpClient) { }

  obterIndicadorAlertaVigente(): Observable<IndicadorAlerta> {
    return this.http.get(this.resourceUrl + '/indicador-alerta/obter-vigente',
      {headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')});
  }

  atualizarIndicadorDeAlerta(indicadorAlerta: IndicadorAlerta): Observable<any> {
    return this.http.post(this.resourceUrl + '/indicador-alerta/atualizar', indicadorAlerta);
  }

  listarHistorico(page: Pageable) : Observable<HistoricoIndicadores[]> {
    return this.http.get<HistoricoIndicadores[]>(this.resourceUrl + '/indicador-alerta/historico',
      {headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true'), params: this.construirParametros(page)});
  }

  restaurarIndicador(id: number) : Observable<any> {
    return this.http.post(this.resourceUrl + `/indicador-alerta/restaurar/${id}`,
      {headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')});
  }

}
