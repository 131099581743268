import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CepPipe } from './pipes/cep.pipe';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { FomartStringDatePipe } from './pipes/fomart-string-date.pipe';
import { FomartStringDataHoraPipe } from './pipes/fomart-string-dateHora.pipe';
import { FormataDataHoraPipe } from './pipes/formata-dataHora.pipe';
import { NcmPipe } from './pipes/ncm.pipe';
import { FormataDecimalPipe } from './pipes/formata-decimal.pipe';
import { OrderDirective } from './directives/order.directive';
import { OrderByDirective } from './directives/order-by.directive';
import { TableMessageComponent } from './table-message/table-message.component';
import { FormataDataHoraExtensoPipe } from './pipes/formata-DataHora-Extenso.pipe';

@NgModule({
  declarations: [
    NcmPipe,
    CepPipe,
    FormataDataHoraPipe,
    CpfCnpjPipe,
    FomartStringDatePipe,
    FomartStringDataHoraPipe,
    FormataDecimalPipe,
    FormataDataHoraExtensoPipe,
    OrderDirective,
    OrderByDirective,
    TableMessageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NcmPipe,
    CepPipe,
    FormataDataHoraPipe,
    CpfCnpjPipe,
    FomartStringDatePipe,
    FomartStringDataHoraPipe,
    FormataDataHoraExtensoPipe,
    FormataDecimalPipe,
    OrderByDirective,
    OrderDirective,
    TableMessageComponent
  ],
})
export class SharedModule { }
