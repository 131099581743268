import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-table-message]',
  templateUrl: './table-message.component.html',
  styleUrls: ['./table-message.component.css']
})
export class TableMessageComponent implements OnInit {

  @Input()
  message: string = 'Nenhum registro encontrado.';

  constructor() { }

  ngOnInit(): void {
  }

}
