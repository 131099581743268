import { Pipe, PipeTransform } from '@angular/core';
import { IbamaUtil } from 'src/app/util/ibama-util';

@Pipe({
  name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {

  transform(value: string): any {

    if (value == null || value == '') {
      return "";
    }

    if (isNaN(value as any)) {
      return value;
    }

    value = IbamaUtil.removerCaracteres(value.toString());

    if (value.length == 11) {
      value = value.substr(0, 3) + '.' + value.substr(3, 3) + '.' + value.substr(6, 3) + '-' + value.substr(9, 2);

    } else if (value.length == 14) {
      value = value.substr(0, 2) + '.' + value.substr(2, 3) + '.' + value.substr(5, 3) + '/' + value.substr(8, 4) + '-' + value.substr(12, 2);
    }

    return value;
  }

}
