import { LabelType, Options } from '@angular-slider/ngx-slider';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AlertMessageService } from '@serpro/ngx-dsgovbr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { delay, finalize } from 'rxjs/operators';
import { ModeloSelect } from 'src/app/equipes/models/modelo.models';
import { LpcoService } from 'src/app/lpco/lpco.service';
import { PAUBRASIL_MESSAGES } from 'src/app/shared/enum/pau-brasil-messages.enum';
import { FormataDataHoraExtensoPipe } from 'src/app/shared/pipes/formata-DataHora-Extenso.pipe';
import { AcessibilidadeUtil } from 'src/app/util/acessibilidadeUtil';
import { Pageable } from 'src/app/util/pageable-request';

import { Cor } from '../model/cor';
import { HistoricoIndicadores } from '../model/historico-indicadores.models';
import { IndicadorAlerta } from '../model/indicador-alerta';
import { ModeloIndicador } from '../model/modelo-indicador';
import { ParametroRiscoService } from '../parametro-risco.service';
import { DestaqueNCM } from '../model/destaque-ncm.model';

@Component({
  selector: 'app-parametro-risco',
  templateUrl: './parametro-risco.component.html',
  styleUrls: ['./parametro-risco.component.scss'],
  providers: [FormataDataHoraExtensoPipe]
})

export class ParametroRiscoComponent implements OnInit, AfterViewInit {

  setarAbaIndicadores!: boolean;
  setarAbaHistorico!: boolean;
  boxed = false;

  modalConfirmarGravacao!: BsModalRef;
  @ViewChild('templateModalConfirmar') templateModalConfirmar?: TemplateRef<any>;
  msgConfirmacaoAlterarIndicadorAlerta = "Tem certeza que deseja alterar os parâmetros do indicador de alerta da LPCO?";

  modalConfirmarRestaracao!: BsModalRef;
  @ViewChild('templateModalRestaurar') templateModalRestaurar!: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  private actionsTemplate!: TemplateRef<any>;

  @ViewChild('dataHoraInicioTemplate', { static: true })
  private dataHoraInicioTemplate!: TemplateRef<any>;

  @ViewChild('dataHoraFinalTemplate', { static: true })
  private dataHoraFinalTemplate!: TemplateRef<any>;

  @ViewChild('autorTemplate', { static: true })
  private autorTemplate!: TemplateRef<any>;

  @ViewChild('parametroIndicadorTemplate', { static: true })
  private parametroIndicadorTemplate!: TemplateRef<any>;

  @ViewChild('situacaoTemplate', { static: true })
  private situacaoTemplate!: TemplateRef<any>;

  @ViewChild('indicadorVermelhoTemplate', { static: true })
  private indicadorVermelhoTemplate!: TemplateRef<any>;

  @ViewChild('indicadorAmareloTemplate', { static: true })
  private indicadorAmareloTemplate!: TemplateRef<any>;

  @ViewChild('indicadorVerdeTemplate', { static: true })
  private indicadorVerdeTemplate!: TemplateRef<any>;

  @ViewChild('modeloResumidoTemplate', { static: true })
  private modeloResumidoTemplate!: TemplateRef<any>;

  @ViewChild('modeloNcmTemplate', { static: true })
  private modeloNcmTemplate!: TemplateRef<any>;

  @ViewChild('modeloDestaqueTemplate', { static: true })
  private modeloDestaqueTemplate!: TemplateRef<any>;

  @ViewChild('corTemplate', { static: true })
  private corTemplate!: TemplateRef<any>;

  @ViewChild('actionExcluirModeloIndicadorActionTemplate', { static: true })
  private actionExcluirModeloIndicadorActionTemplate!: TemplateRef<any>;

  isCollapsed: boolean = true;
  isFocused: boolean = false;

  loadingVisible: boolean = false;
  loadingPendentes: number = 0;
  loadingMensage: string = 'Carregando parametrizações de indicadores de riscos...';

  mensagem: string = 'Edição de parâmetros';
  topicos: any[] = [
    {item: 'Ativar e desativar indicadores:'},
    {item: 'Definição das cores:'},
  ]
  linhasTopico1: any[] = [
    {item: 'Utilize as caixas de seleção (checkbox) para escolher quais indicadores estarão ativos. Os indicadores não selecionados serão desconsiderados no cálculo.'}
  ]
  linhasTopico2: any[] = [
    {item: 'Nos indicadores que possuem controle deslizante, utilize o controle para ajustar as faixas de valores correspondentes às cores: verde, amarelo e vermelho.'},
    {item: 'Nos indicadores que possuem lista, selecione a cor apropriada para cada opção.'}
  ]

  tempoDeExibicaoMensagem: number = 5000;

  percDeferimento?: boolean = true;
  percIndeferimento?: boolean = true;
  percCancelamento?: boolean = true;
  situacaoCTF?: boolean = true;
  possuiInfracao?: boolean = true;
  possuiEmbargos?: boolean = true;

  cores = Object.values(Cor);
  coresCTFRegular: Cor[] = [Cor.AMARELO, Cor.VERDE];
  coresCTFIrregular: Cor[] = [Cor.AMARELO, Cor.VERMELHO];
  coresPossuiAutoSim: Cor[] = [Cor.AMARELO, Cor.VERMELHO];
  coresPossuiAutoNao: Cor[] = [Cor.AMARELO, Cor.VERDE];
  coresPossuiEmbargosSim: Cor[] = [Cor.AMARELO, Cor.VERMELHO];
  coresNaoPossuiEmbargos: Cor[] = [Cor.AMARELO, Cor.VERDE];
  coresModeloSelect: Cor[] = [Cor.AMARELO, Cor.VERMELHO];

  valorInicialIndicadorPercentual = 0;
  valorFinalIndicadorPercentual = 100;
  txtIndicadorPercentual = '%';

  valorInicialTempoCadastroCTF = 0;
  valorFinalTempoCadastroCTF = 130;
  valorLimiteTempoCadastroCTF = 120;
  txtMesCadastroCTF = 'MÊS';
  txtMesesCadastroCTF = 'MESES';

  indicadorOriginal: IndicadorAlerta = new IndicadorAlerta();
  indicador: IndicadorAlerta = new IndicadorAlerta();
  indicadorRestaurar: number = 0;

  private listaModeloSubject = new BehaviorSubject<ModeloSelect[]>([]);
  listaModeloSelect$ = this.listaModeloSubject.asObservable();
  listaModeloSelectOriginal: ModeloSelect[] = [];
  listaModeloSelect: ModeloSelect[] = [];

  ncms: { ncm: string }[] = [];
  ncmSelecionado: string | undefined;
  comboNcmHabilidado: boolean = false;
  descriptionNcmSelect: string = '';
  placeholderNcmSelect: string = '';
  erroNcmSelect: boolean = false;

  destaques: DestaqueNCM[] = [];
  destaqueSelecionado: string | undefined;
  comboDestaqueHabilitado: boolean = false;
  descriptionDestaqueSelect: string = '';
  placeholderDestaqueSelect: string = '';
  erroDestaqueSelect: boolean = false;

  comboCorModeloHabilitado: boolean = false;
  placeholderCorModeloSelect: string = '';

  btnAddModeloHabilitado: boolean = false;

  totalModelos: number = 0;
  listaModelosIndicador: ModeloIndicador[] = [];


  // TableCorModelos
  modeloSelecionado: ModeloSelect | undefined;
  corModeloSelecionado: string | undefined;

  public columnsModelosIndicador: any[] = [];

  options: Options = {
    floor: this.valorInicialIndicadorPercentual,
    ceil: this.valorFinalIndicadorPercentual,
    showOuterSelectionBars: true,
    disabled: false,
    translate: (value: number, label: LabelType): string => {
      if (label === LabelType.Floor || label === LabelType.Ceil) {
        return '';
      }
      return String(value + this.txtIndicadorPercentual);
    },
  };

  optionsTempoCadastroCTF: Options = {
    floor: this.valorInicialTempoCadastroCTF,
    ceil: this.valorFinalTempoCadastroCTF,
    maxLimit: this.valorLimiteTempoCadastroCTF,
    showOuterSelectionBars: true,
    disabled: false,
    translate: (value: number, label: LabelType): string => {
      if (label === LabelType.Floor || label === LabelType.Ceil) {
        return '';
      }
      if (value <= 1) {
        return String(`${value} ${this.txtMesCadastroCTF}`);
      }
      return String(`${value} ${this.txtMesesCadastroCTF}`);
    }
  };

  grRiscoSituacaoCTFIrreg?: number;
  grRiscoSituacaoCTFReg?: number;
  grRiscoPossuiInfracaoSim?: number;
  grRiscoPossuiInfracaoNao?: number;
  grRiscoPossuiEmbargosSim?: number;
  grRiscoPossuiEmbargosNao?: number;

  pageSize: number = 5;
  currentPage: number = 1;
  sort: any;
  pageable = new Pageable(0, 5);
  columnsHistorico: any[] = [];
  totalElementosHistorico: number = 0;

  listaHistorico: HistoricoIndicadores[] = [];
  columnsDetalhe: any[] = [];

  config_md = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md',
  };

  constructor(private parametroRiscoService: ParametroRiscoService,
    public alertMessageService: AlertMessageService,
    private modalService: BsModalService,
    private lpcoService: LpcoService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setarAbaIndicadores = true;
    this.setarAbaHistorico = false;
    this.pageable.setSort(2, 'dataHoraInicio');
    this.carregarModelosLpco();
    this.carregarIndicadorVigente();
    this.carregarCabecalhoHistorico();
    this.carregarCabecalhoHistoricoDetalhe();
    this.carregarCabecalhoModelos();
    this.subscribeDefinirPlaceholderModeloSelect();
  }

  private addProcessoPendente() {
    this.loadingPendentes++;
    this.loadingVisible = true;
  }

  private removeProcessoPendente() {
    this.loadingPendentes--;
    if (this.loadingPendentes <= 0) {
      this.loadingPendentes = 0;
      this.loadingVisible = false;
    }

  }

  carregarIndicadorVigente () {
    this.addProcessoPendente();
    this.parametroRiscoService.obterIndicadorAlertaVigente()
    .pipe(finalize(() => {
      this.removeProcessoPendente();
    }))
    .subscribe((resultado: IndicadorAlerta) => {
      this.indicador = resultado;
      this.carregarValoresAcessibilidade();
      this.definirValoresPadraoIndicadores();
      this.indicadorOriginal = {...this.indicador};
      this.indicadorOriginal.modelosIndicador = [...resultado.modelosIndicador!]
      if (this.indicador.modelosIndicador) {
        this.listaModelosIndicador = [...this.indicador.modelosIndicador];
        this.ordenarListaModelos(this.listaModelosIndicador);
      }
    },
    (error) => {
      this.alertMessageService.error(PAUBRASIL_MESSAGES.ERRO_CARREGAMENTO, '',[], undefined, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    });

    setTimeout(() => {
      //this.loadingVisible = false;
    }, 1000);
  }

  carregarModelosLpco() {
    this.addProcessoPendente();
    this.lpcoService.obterModelos()
    .pipe(finalize(() => {
      this.removeProcessoPendente();
    }))
    .subscribe((resultado: any) => {
      this.totalModelos = resultado.length;
      if (this.totalModelos > 0) {
        this.listaModeloSelect = [...resultado];
        this.listaModeloSelect.sort((a, b) => a.nomeModeloResumido!.localeCompare(b.nomeModeloResumido!));
        this.listaModeloSelectOriginal = [...resultado]
      } else {
        this.listaModeloSelect = [];
        this.listaModeloSelectOriginal = [];
      }
    },
    (error) => {
      this.alertMessageService.error(PAUBRASIL_MESSAGES.LISTA_MODELOS_NAO_CARREGADA, '',[], {delay: 5000}, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    });
  }

  onChangeModelo() {
    this.carregarNcmsModelo();
    this.onChangeNcm();
  }

  limparComboNCM() {
    this.comboNcmHabilidado = false;
    this.ncms = [];
    this.ncmSelecionado = undefined;
    this.descriptionNcmSelect = '';
    this.placeholderNcmSelect = '';
    this.erroNcmSelect = false;
  }

  carregarNcmsModelo() {
    this.limparComboNCM();
    if (this.modeloSelecionado?.codigoModelo) {
      this.descriptionNcmSelect = 'Carregando...';
      this.lpcoService.obterNcmsByModelo(this.modeloSelecionado?.codigoModelo).subscribe((resultado: any) => {
        if (resultado && resultado.length > 0) {
          this.ncms = resultado;
          this.ncms.sort((x1, x2) => x1.ncm.localeCompare(x2.ncm));
          this.ncms.forEach(x => x.ncm = this.formataNcm(x.ncm))
          this.comboNcmHabilidado = true;
          this.descriptionNcmSelect = '';
          this.placeholderNcmSelect = 'Selecione uma NCM';
        } else {
          this.descriptionNcmSelect = 'Nenhuma NCM localizada para o Modelo.';
          this.erroNcmSelect = true;
        }
      },
      (error) => {
        this.descriptionNcmSelect = PAUBRASIL_MESSAGES.LISTA_NCM_NAO_CARREGADA;
        this.erroNcmSelect = true;
      });
    }
  }

  onChangeNcm() {
    this.carregarDestaquesNcm();
    this.onChangeDestaque();
  }

  limparComboDestaque() {
    this.comboDestaqueHabilitado = false;
    this.destaques = [];
    this.destaqueSelecionado = undefined;
    this.descriptionDestaqueSelect = '';
    this.placeholderDestaqueSelect = '';
    this.erroDestaqueSelect = false;
  }

  carregarDestaquesNcm() {
    this.limparComboDestaque();
    if (this.modeloSelecionado?.codigoModelo && this.ncmSelecionado) {
      let codigoNcm = this.ncmSelecionado.replace(/\D/g, '');
      this.descriptionDestaqueSelect = 'Carregando...';
      this.lpcoService.obterDestaquesByModeloAndNcm(this.modeloSelecionado?.codigoModelo, codigoNcm).subscribe((resultado: DestaqueNCM[]) => {
        if (resultado && resultado.length > 0) {
          this.destaques = resultado;
          this.destaques.sort((x1, x2) =>  x1.descricao && x2.descricao ? x1.descricao?.localeCompare(x2.descricao) : 0);
          this.destaques.forEach(x => x.ncm = codigoNcm);
          this.comboDestaqueHabilitado = true;
          this.descriptionDestaqueSelect = '';
          this.placeholderDestaqueSelect = 'Selecione um Destaque';
          this.habilitarCorModelo(false);
        } else {
          this.descriptionDestaqueSelect = 'Nenhum Destaque localizado para o Modelo/NCM.';
          this.erroDestaqueSelect = true;
          this.habilitarCorModelo(true);
        }
      },
      (error) => {
        this.habilitarCorModelo(true);
        this.descriptionDestaqueSelect = PAUBRASIL_MESSAGES.LISTA_DESTAQUES_NCM_NAO_CARREGADA;
        this.erroDestaqueSelect = true;
      });
    }
  }

  habilitarCorModelo(habilitado: boolean) {
    if (habilitado) {
      this.comboCorModeloHabilitado = true;
      this.placeholderCorModeloSelect = "Selecione uma cor"
    } else {
      this.corModeloSelecionado = undefined;
      this.comboCorModeloHabilitado = false;
      this.placeholderCorModeloSelect = ""
    }
  }

  onChangeDestaque() {
    this.habilitarBotarAddModelo();
    this.habilitarCorModelo(this.destaqueSelecionado ? true : false);
  }

  onChangeCorModelo() {
    this.habilitarBotarAddModelo();
  }

  habilitarBotarAddModelo() {
    const isDestaqueObrigatorio = this.ncmSelecionado && (!this.destaques || this.destaques.length == 0) ? false : true;
    if (this.modeloSelecionado && this.ncmSelecionado && (!isDestaqueObrigatorio || this.destaqueSelecionado) && this.corModeloSelecionado) {
      this.btnAddModeloHabilitado = true;
    } else {
      this.btnAddModeloHabilitado = false;
    }
  }

  formataNcm(codigo: string | undefined): string {
    if (codigo && codigo.replace(/\D/g, '').length > 6) {
      const ncm = codigo.replace(/\D/g, '');
      return ncm.substring(0,4) + '.' + ncm.substring(4,6) + '.' + ncm.substring(6);
    }
    return codigo ? codigo : '';
  }

  subscribeDefinirPlaceholderModeloSelect() {
    this.listaModeloSelect$
    .pipe(delay(1000))
    .subscribe(() => {
      if(this.listaModeloSelect.length < 1) {
        this.placeholderModeloSelect = "Nenhum modelo de LPCO disponível";
      } else {
        this.placeholderModeloSelect = "";
      }
    });
  }

  incluirModeloIndicador() {
    let modeloIndicador = this.buildModeloToAdd();
    if (modeloIndicador && this.validaModelo(modeloIndicador)) {
      this.listaModelosIndicador.push(modeloIndicador);
      this.ordenarListaModelos(this.listaModelosIndicador);
      this.listaModelosIndicador = [...this.listaModelosIndicador];
      this.indicador.modelosIndicador = [...this.listaModelosIndicador];
      this.resetSelectsModeloIndicador();
    }
  }

  ordenarListaModelos(lista: ModeloIndicador[]) {
    if (lista) {
      lista.sort((x1, x2) => {
        let retorno = 0;

        if (x1.nomeModeloResumido && x2.nomeModeloResumido) {
          retorno = x1.nomeModeloResumido.localeCompare(x2.nomeModeloResumido);
        } else if (x1.nomeModeloResumido && !x2.nomeModeloResumido) {
          retorno = 1;
        } else if (!x1.nomeModeloResumido && x2.nomeModeloResumido) {
          retorno = -1;
        }

        if (retorno == 0) {
          if (x1.codigoNCM && x2.codigoNCM) {
            retorno = x1.codigoNCM.localeCompare(x2.codigoNCM);
          } else if (x1.codigoNCM && !x2.codigoNCM) {
            retorno = 1;
          } else if (!x1.codigoNCM && x2.codigoNCM) {
            retorno = -1;
          }
        }

        if (retorno == 0) {
          if (x1.descricaoDestaque && x2.descricaoDestaque) {
            retorno = x1.descricaoDestaque.localeCompare(x2.descricaoDestaque);
          } else if (x1.descricaoDestaque && !x2.descricaoDestaque) {
            retorno = 1;
          } else if (!x1.descricaoDestaque && x2.descricaoDestaque) {
            retorno = -1;
          }
        }
        return retorno;
      });
    }
  }

  buildModeloToAdd(): ModeloIndicador | undefined {
    if (this.modeloSelecionado?.codigoModelo && this.ncmSelecionado && this.corModeloSelecionado) {
      let modeloIndicador = new ModeloIndicador();
      modeloIndicador.codigoModelo = this.modeloSelecionado?.codigoModelo;
      modeloIndicador.nomeModeloResumido = this.modeloSelecionado?.nomeModeloResumido;
      modeloIndicador.corModelo = this.corModeloSelecionado;
      modeloIndicador.codigoNCM = this.ncmSelecionado.replace(/\D/g, '');
      if (this.destaqueSelecionado) {
        modeloIndicador.codigoDestaque = this.destaqueSelecionado;
        modeloIndicador.descricaoDestaque = this.destaques.find(x => x.id === this.destaqueSelecionado)?.descricao;
      }
      return modeloIndicador;
    }
    return undefined;
  }

  isModelosIguais(modelo1: ModeloIndicador, modelo2: ModeloIndicador): boolean {
    if (modelo1 && modelo2) {
      return (modelo1.codigoModelo === modelo2.codigoModelo
        && this.formataNcm(modelo1.codigoNCM) === this.formataNcm(modelo2.codigoNCM)
        && ((!modelo1.codigoDestaque && !modelo2.codigoDestaque)
          || modelo1.codigoDestaque === modelo2.codigoDestaque));
    }
    return false;
  }

  validaModelo(modelo: ModeloIndicador): boolean {
    let modeloIgual = this.listaModelosIndicador.find(x => this.isModelosIguais(x, modelo));
    if (modeloIgual) {
      const msg = 'Modelo de LPCO / NCM' + (modelo.codigoDestaque ? ' / Destaque' : '') + ' já cadastrados';
      this.alertMessageService.error(msg, '',[], {delay: 5000}, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
      return false;
    } else if (!modelo.codigoModelo) {
      this.alertMessageService.error('Selecione um modelo para incluir.', '',[], {delay: 5000}, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    } else if (!modelo.codigoNCM) {
      this.alertMessageService.error('Selecione uma NCM para incluir o modelo.', '',[], {delay: 5000}, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    } else if (!modelo.corModelo) {
      this.alertMessageService.error('Selecione uma cor para incluir o modelo.', '',[], {delay: 5000}, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    }
    return true;
  }

  removerModeloIndicador(modeloIndicador: ModeloIndicador) {
    this.listaModelosIndicador = this.listaModelosIndicador.filter(modelo => !this.isModelosIguais(modelo, modeloIndicador));
    this.indicador.modelosIndicador = [...this.listaModelosIndicador]
    AcessibilidadeUtil.descrever("Modelo de L P C Ó " + modeloIndicador.nomeModeloResumido + (modeloIndicador.descricaoDestaque == null ? " , com destaque N C M, em branco" : " , com destaque N C M, " + modeloIndicador.descricaoDestaque) + " cor " + modeloIndicador.corModelo +  " removido");
  }

  resetSelectsModeloIndicador() {
    this.modeloSelecionado = undefined;
    this.corModeloSelecionado = undefined;
    this.limparComboDestaque();
    this.limparComboNCM();
    this.habilitarCorModelo(false);
    this.habilitarBotarAddModelo();
  }

  carregarCabecalhoModelos() {
    this.columnsModelosIndicador = [
      { name: 'Modelo de LPCO', prop: 'nomeModeloResumido', headerClass: 'text-left', cellClass: 'cell-text-left', cellTemplate: this.modeloResumidoTemplate, sortable: true},
      { name: 'NCM', headerClass: 'text-center', cellClass: 'text-center', cellTemplate: this.modeloNcmTemplate, sortable: true},
      { name: 'Destaque NCM', prop: 'descricaoDestaque', headerClass: 'text-left', cellClass: 'cell-text-left', cellTemplate: this.modeloDestaqueTemplate, sortable: true},
      { name: 'Cor', prop: 'corModelo', headerClass: 'text-center', cellClass: 'text-center', cellTemplate: this.corTemplate, sortable: true},
      { name: "", headerClass: 'text-center', cellClass: 'text-center', cellTemplate: this.actionExcluirModeloIndicadorActionTemplate, sortable: false, minWidth: 65, maxWidth: 65}
    ];
  }

  getLabelInicioTempoCTF() {
    return `${this.valorInicialTempoCadastroCTF} ${this.txtMesCadastroCTF}`
  }

  getLabelLimiteTempoCTF() {
    return `${this.valorLimiteTempoCadastroCTF} ${this.txtMesesCadastroCTF}+`
  }

  getLabelInicioIndicadorPercentual() {
    return `${this.valorInicialIndicadorPercentual}${this.txtIndicadorPercentual}`
  }

  getLabelFinalIndicadorPercentual() {
    return `${this.valorFinalIndicadorPercentual}${this.txtIndicadorPercentual}`
  }

  definirValoresPadraoIndicadores() {
    const valorInicial = 20;
    const valorFinal = 80;

    if (!this.indicador.inicioAmareloDeferimento && this.indicador.inicioAmareloDeferimento != 0) {
      this.indicador.inicioAmareloDeferimento = valorInicial;
    }

    if (!this.indicador.finalAmareloDeferimento && this.indicador.finalAmareloDeferimento != 0) {
      this.indicador.finalAmareloDeferimento = valorFinal;
    }

    if (!this.indicador.inicioAmareloIndeferimento && this.indicador.inicioAmareloIndeferimento != 0) {
      this.indicador.inicioAmareloIndeferimento = valorInicial;
    }

    if (!this.indicador.finalAmareloIndeferimento && this.indicador.finalAmareloIndeferimento != 0) {
      this.indicador.finalAmareloIndeferimento = valorFinal;
    }

    if (!this.indicador.inicioAmareloCancelamento && this.indicador.inicioAmareloCancelamento != 0)
      this.indicador.inicioAmareloCancelamento = valorInicial;

    if (!this.indicador.finalAmareloCancelamento && this.indicador.finalAmareloCancelamento != 0) {
      this.indicador.finalAmareloCancelamento = valorFinal;
    }

    if (!this.indicador.inicioAmareloTempoCadastrado && this.indicador.inicioAmareloTempoCadastrado != 0) {
      this.indicador.inicioAmareloTempoCadastrado = valorInicial
    }

    if (!this.indicador.finalAmareloTempoCadastrado && this.indicador.finalAmareloTempoCadastrado != 0) {
      this.indicador.finalAmareloTempoCadastrado = valorFinal
    }

    if (!this.indicador.certificadoAtivo) {
      this.indicador.corCertificadoRegular = Cor.VERDE
      this.indicador.corCertificadoIrregular = Cor.VERMELHO
    }

    if (!this.indicador.autosAtivo) {
      this.indicador.corPossuiAutos = Cor.VERMELHO
      this.indicador.corNaoPossuiAutos = Cor.VERDE
    }

    if (!this.indicador.corPossuiEmbargos) {
      this.indicador.corPossuiEmbargos = Cor.VERMELHO
      this.indicador.corNaoPossuiEmbargos = Cor.VERDE
    }
  }

  houveAlteracaoIndicador(): boolean {
    return JSON.stringify(this.indicadorOriginal) !== JSON.stringify(this.indicador);
  }

  onChangeDeferimento(): void {
    //this.options1 = Object.assign({}, this.options1, {disabled: !this.percDeferimento});
  }

  onChangeIndeferimento(): void {
    //this.options2 = Object.assign({}, this.options2, {disabled: !this.percIndeferimento});
  }

  onChangeCancelamento(): void {
    //this.options3 = Object.assign({}, this.options3, {disabled: !this.percCancelamento});
  }

  onChangeSituacaoCTFReg(event: any): void {
    //this.coresgrRiscoSituacaoCTFIrreg = this.cores.filter(cor => cor !== event);
  }

  onChangeSituacaoCTFIrreg(event: any): void {
    //this.coresgrRiscoSituacaoCTFReg = this.cores.filter(cor => cor !== event);
  }

  onChangePossuiAutoSim(event: any): void {
    //this.coresgrRiscoPossuiAutoNao = this.cores.filter(cor => cor !== event);
  }

  onChangePossuiAutoNao(event: any): void {
    //this.coresgrRiscoPossuiAutoSim = this.cores.filter(cor => cor !== event);
  }

  onChangePossuiEmbargosSim(event: any): void {
    //this.coresgrRiscoNaoPossuiEmbargos = this.cores.filter(cor => cor !== event);
  }

  onChangePossuiEmbargosNao(event: any): void {
    //this.coresgrRiscoPossuiEmbargosSim = this.cores.filter(cor => cor !== event);
  }

  onChangeClassificacaoModeloAtivo(event: Event): void {
    this.resetSelectsModeloIndicador();
  }

  cancelarGravacao(): void {
    if(this.modalConfirmarGravacao)
      this.modalConfirmarGravacao.hide();

    this.carregarIndicadorVigente();
  }

  confirmarGravacao(): void {
    if (this.indicador.classificacaoModeloAtivo) {
      if(this.indicador.modelosIndicador == undefined || this.indicador.modelosIndicador.length === 0) {
        this.alertMessageService.error('É obrigatório informar o(s) campo(s): ', 'Modelo de LPCO, NCM e Cor.', [], { delay: this.tempoDeExibicaoMensagem }, 'indicador-alerta');
        AcessibilidadeUtil.lerMensagem('indicador-alerta');
        return;
      }
    }
    if (!this.houveAlteracaoIndicador()) {
      this.alertMessageService.error(PAUBRASIL_MESSAGES.INDICADORES_CONFIGURACOES_IGUAIS, '', [], { delay: this.tempoDeExibicaoMensagem }, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
      return;
    }
    if (this.templateModalConfirmar)
      this.modalConfirmarGravacao = this.modalService.show(this.templateModalConfirmar, this.config_md);
  }

  atualizar() {
    this.modalConfirmarGravacao.hide();
    this.alertMessageService.dismissAll();
    this.loadingVisible = true;
    this.loadingMensage = "Aguarde, gravando Parâmetros..."
    AcessibilidadeUtil.descrever('Aguarde, gravando Parâmetros');
    this.parametroRiscoService.atualizarIndicadorDeAlerta(this.indicador)
     .pipe(finalize(() => {
       this.loadingVisible = false;
       this.loadingMensage = '';
    }))
    .subscribe((resultado: any) => {
      this.indicadorOriginal = {...this.indicador};
      this.alertMessageService.dismissAll();
      this.alertMessageService.success(PAUBRASIL_MESSAGES.INDICADORES_ALTERADOS_SUCESSO, '', [], undefined, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    })

  }

  onRowExpanded(event: any) {
    console.log(event);
  }

  listarHistorico(event: any) {
    if (event) {
      this.carregarHistorico(this.pageable);
    }
  }

  carregarHistorico(pageable: Pageable) {
    this.loadingVisible = true;
    this.loadingMensage = 'Aguarde, carregando histórico...';
    AcessibilidadeUtil.descrever('Aguarde, carregando histórico');
    this.setarAbaIndicadores = false;
    this.setarAbaHistorico = true;
    this.parametroRiscoService.listarHistorico(pageable)
      .pipe(finalize(() => {
        this.loadingVisible = false;
      }))
      .subscribe(
      (resultado: any) => {
        this.totalElementosHistorico = resultado.totalElements;
        if (this.totalElementosHistorico > 0) {
          AcessibilidadeUtil.descrever('Historico carregado, página: ' + (this.pageable.page + 1) + ', quantidade de registros: ' + resultado.content.length);
          this.listaHistorico = resultado.content;
        } else {
          this.listaHistorico = [];
          this.alertMessageService.info(PAUBRASIL_MESSAGES.NENHUM_REGISTRO_ENCONTRADO, '', [], undefined, 'indicador-alerta');
          AcessibilidadeUtil.lerMensagem('indicador-alerta');
        }
      },
      (error: any) => {
        this.alertMessageService.error(PAUBRASIL_MESSAGES.ERRO_CARREGAMENTO, '', [], undefined, 'indicador-alerta');
        AcessibilidadeUtil.lerMensagem('indicador-alerta');
      }
    )
  }

  onCurrentPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.pageable.page = pageNumber - 1;
    this.loadingVisible = false;
    this.carregarHistorico(this.pageable);
  }

  onPageSizeChange(pageSize: number) {
    this.currentPage = 1;
    this.pageSize = pageSize;
    this.pageable.setPage(0);
    this.pageable.setSize(pageSize);
    this.loadingVisible = false;
    this.carregarHistorico(this.pageable);
  }

  onSort(event: { sorts: any[]; }) {
    this.sort = event.sorts[0];
    const direction = this.sort.dir === 'asc' ? 1 : 2;
    this.pageable.setPage(0);
    if (this.sort.prop) {
      this.pageable.setSort(direction, this.sort.prop);
    }
  }

  carregarCabecalhoHistorico() {
    this.columnsHistorico = [
      { name: 'Data/Hora inicial', prop: 'dataHoraInicio', headerClass: 'text-left', cellClass: 'text-left', cellTemplate: this.dataHoraInicioTemplate},
      { name: 'Data/Hora final', prop: 'dataHoraFinal', headerClass: 'text-left', cellClass: 'text-left', cellTemplate: this.dataHoraFinalTemplate},
      { name: 'Autor', prop: 'autor', headerClass: 'text-left', cellClass: 'text-left', cellTemplate: this.autorTemplate},
      { name: 'Ação', headerClass: 'text-center', cellClass: 'text-center', cellTemplate: this.actionsTemplate, sortable: false, minWidth: 70,  maxWidth: 70}
    ];
  }

  carregarCabecalhoHistoricoDetalhe() {
    this.columnsDetalhe = [
      { name: 'Parâmetro do Indicador de Alerta', prop: 'nomeIndicador', headerClass: 'text-left', cellClass: 'text-left', sortable: false, cellTemplate: this.parametroIndicadorTemplate, minWidth: 380, maxWidth: 400},
      { name: 'Situação', prop: 'indicadorAtivo', headerClass: 'text-left', cellClass: 'text-left', sortable: false, cellTemplate: this.situacaoTemplate,  minWidth: 180, maxWidth: 230},
      { name: 'Vermelho', prop: 'indicadorVermelho', headerClass: 'text-left', cellClass: 'text-left', sortable: false, cellTemplate: this.indicadorVermelhoTemplate},
      { name: 'Amarelo', prop: 'indicadorAmarelo', headerClass: 'text-left', cellClass: 'text-left', sortable: false, cellTemplate: this.indicadorAmareloTemplate},
      { name: 'Verde', prop: 'indicadorVerde', headerClass: 'text-left', cellClass: 'text-left', sortable: false, cellTemplate: this.indicadorVerdeTemplate},
    ];
  }

  abrirModalrestaurar(linhaSelecionada: any) {
    this.alertMessageService.dismissAll();
    this.indicadorRestaurar = linhaSelecionada.id;
    this.modalConfirmarRestaracao = this.modalService.show(this.templateModalRestaurar, this.config_md);
  }

  restaurar() {
    this.alertMessageService.dismissAll();
    this.loadingVisible = true;
    this.loadingMensage = "Restaurando indicador...";
    AcessibilidadeUtil.descrever('Restaurando indicador');
    this.parametroRiscoService.restaurarIndicador(this.indicadorRestaurar)
    .pipe(finalize(() => {
      this.loadingVisible = false;
      this.modalConfirmarRestaracao.hide();
    }))
    .subscribe(() => {
      this.alertMessageService.success(PAUBRASIL_MESSAGES.INDICADOR_RESTAURADO_SUCESSO, '', [], undefined, 'indicador-alerta');
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
      this.carregarIndicadorVigente();
      this.setarAbaIndicadores = true;
      this.setarAbaHistorico = false;
    }, (erro: any) => {

      if (erro.error.status == 500) {
        this.alertMessageService.error(PAUBRASIL_MESSAGES.INDICADOR_RESTAURADO_ERRO, '', [], undefined, 'indicador-alerta');
      }

      if (erro.error.status == 400) {
        this.alertMessageService.error(erro.error.detail, '', [], undefined, 'indicador-alerta');
      }
      AcessibilidadeUtil.lerMensagem('indicador-alerta');
    });
  }

  cancelar() {
    this.modalConfirmarRestaracao.hide();
  }

  // ACESSIBILIDADE PARA ALTERAR PARâMETROS DO INDICADOR DE ALERTA

  @ViewChild('sliderElement1') sliderElement1!: ElementRef;
  @ViewChild('sliderElement2') sliderElement2!: ElementRef;
  @ViewChild('sliderElement3') sliderElement3!: ElementRef;

  private observersInitialized = false;

  // Ajuste para acessar propriedades dinamicamente com uma interface
  [key: string]: any; // Permite o uso dinâmico de propriedades

  minValue1: number = 0;
  maxValue1: number = 0;
  valorAtual1: string = '';

  minValue2: number = 0;
  maxValue2: number = 0;
  valorAtual2: string = '';

  minValue3: number = 0;
  maxValue3: number = 0;
  valorAtual3: string = '';

  carregarValoresAcessibilidade() {
    this.minValue1 = this.indicador.inicioAmareloDeferimento!;
    this.maxValue1 = this.indicador.finalAmareloDeferimento!;
    this.valorAtual1 = `${this.indicador.inicioAmareloDeferimento} por cento`;

    this.minValue2 = this.indicador.inicioAmareloIndeferimento!;
    this.maxValue2 = this.indicador.finalAmareloIndeferimento!;
    this.valorAtual2 = `${this.indicador.inicioAmareloIndeferimento} por cento`;

    this.minValue3 = this.indicador.inicioAmareloCancelamento!;
    this.maxValue3 = this.indicador.finalAmareloCancelamento!;
    this.valorAtual3 = `${this.indicador.inicioAmareloCancelamento} por cento`;
  }

  ngAfterViewInit(): void {
    // console.log('DOM completamente carregado! Inicializando observadores de mutação isolados...');
    // this.createIsolatedObserver(this.sliderElement1, 'minValue1', 'maxValue1', 'valorAtual1', 'sliderLabel-1');
    // this.createIsolatedObserver(this.sliderElement2, 'minValue2', 'maxValue2', 'valorAtual2', 'sliderLabel-2');
    // this.createIsolatedObserver(this.sliderElement3, 'minValue3', 'maxValue3', 'valorAtual3', 'sliderLabel-3');
  }

  createIsolatedObserver(
    sliderElement: ElementRef,
    minProp: string,
    maxProp: string,
    valorAtualProp: string,
    sliderLabelId: string
  ) {
    if (sliderElement && sliderElement.nativeElement) {
      // Configura um MutationObserver para monitorar alterações no DOM
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            const minThumb = sliderElement.nativeElement.querySelector('.ngx-slider-pointer-min');
            const maxThumb = sliderElement.nativeElement.querySelector('.ngx-slider-pointer-max');

            if (minThumb) {
              this.configureThumb(minThumb, minProp, valorAtualProp, 'Valor mínimo', sliderLabelId);
            }

            if (maxThumb) {
              this.configureThumb(maxThumb, maxProp, valorAtualProp, 'Valor máximo', sliderLabelId);
            }
          }
        }
      });

      // Inicia a observação no elemento para mudanças no DOM
      observer.observe(sliderElement.nativeElement, { childList: true, subtree: true });
      console.log(`Observador de mutação criado para: ${sliderLabelId}`);
    }
  }

  configureThumb(
    thumbElement: HTMLElement,
    valueProp: string,
    valorAtualProp: string,
    label: string,
    sliderLabelId: string
  ) {
    this.renderer.setAttribute(thumbElement, 'tabindex', '0');
    this.renderer.setAttribute(thumbElement, 'role', 'p');
    this.renderer.setAttribute(thumbElement, 'aria-valuemin', '0');
    this.renderer.setAttribute(thumbElement, 'aria-valuemax', '100');
    this.renderer.setAttribute(thumbElement, 'aria-valuenow', String(this[valueProp]));
    this.renderer.setAttribute(thumbElement, 'aria-valuetext', `${this[valueProp]}%`);
    this.renderer.setAttribute(thumbElement, 'aria-label', `${label}: ${this[valueProp]}%`);
    this.renderer.setAttribute(thumbElement, 'aria-labelledby', sliderLabelId);

    this.renderer.listen(thumbElement, 'focus', () => {
      console.log(`Foco na bolinha ${label}!`);
      this[valorAtualProp] = this.getTextoPorcentagem(this[valueProp].toString());

      setTimeout(() => {
        this.renderer.setAttribute(thumbElement, 'aria-valuenow', String(this[valueProp]));
        this.renderer.setAttribute(thumbElement, 'aria-valuetext', `${this[valueProp]}%`);
        this.renderer.setAttribute(thumbElement, 'aria-label', `${label}: ${this[valueProp]}%`);
      }, 50);
    });
  }

  getTextoPorcentagem(valor: string): string {
    return `${valor} por cento`;
  }

  descrever(textContent: string) {
    AcessibilidadeUtil.descrever(textContent);
  }


  // ACESSIBILIDADE PARA HISTÓRICO DO INDICADOR DE ALERTA
  getDataHora(dataHora: string): string {
    return FormataDataHoraExtensoPipe.transform(dataHora);
  }

  getPorCento(texto: string): string {
    return AcessibilidadeUtil.getPorCento(texto);
  }

  getDescricaoPorcDeferimento(valorInicio: number, valorFinal: number): string {
    return 'Vermelho inferior a ' + valorInicio + ' por cento, amarelo de ' + valorInicio + 'por cento até ' + valorFinal + 'por cento. E verde superior a ' + valorFinal + ' por cento';
  }

  getDescricaoPorcIndeferimento(valorInicio: number, valorFinal: number): string {
    return 'Verde inferior a ' + valorInicio + ' por cento, amarelo de ' + valorInicio + 'por cento até ' + valorFinal + 'por cento. E vermelho superior a ' + valorFinal + ' por cento';
  }

  getDescricaoPorcCancelamento(valorInicio: number, valorFinal: number): string {
    return 'Verde inferior a ' + valorInicio + ' por cento, amarelo de ' + valorInicio + 'por cento até ' + valorFinal + 'por cento. E vermelho superior a ' + valorFinal + ' por cento';
  }

  getDescricaoReqCTF(valorInicio: number, valorFinal: number) {
    return 'Vermelho inferior a ' + valorInicio + ' meses, amarelo entre ' + valorInicio + ' e ' + valorFinal + 'meses. E verde superior a ' + valorFinal + ' meses';
  }

  getDescricaoDestaque(texto: string): string {
    return texto == null ? 'Destaque: em branco' : 'Destaque: ' + texto;
  }

  expandirFieldSet() {
    if (this.isFocused) {
      this.isCollapsed = false;
    }
  }

  manterAberto(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Tab') {
      keyboardEvent.stopPropagation();
    }
  }

  alternarColapsoFieldSet() {
    this.isCollapsed = !this.isCollapsed;
  }

  ativarFocoPorTecladoFieldSet() {
    setTimeout(() => {
      this.isCollapsed = false;
    }, 195);
  }
}
