import { NgModule } from '@angular/core';

import {
  GovBrAccordionModule,
  GovBrAlertMessagesModule,
  GovBrCardModule,
  GovBrCommonModule,
  GovBrConfirmationsModule,
  GovBrDatePickerModule,
  GovBrFieldsetModule,
  GovBrFileModule,
  GovBrHeaderModule,
  GovBrLocalMessageModule,
  GovBrInputModule,
  GovBrPipesModule,
  GovBrPickListModule,
  GovBrSecondTabModule,
  GovBrSelectModule,
  GovBrSubFieldsetModule,
  GovBrTabModule,
  GovBrTableModule,
  GovBrPaginationModule,
  GovBrTextAreaModule,
  GovBrTitleModule,
  GovBrVerticalTabModule,
  GovBrDirectivesModule,
  GovBrMainLayoutModule,
  GovBrFabPaneModule,
  GovBrConfirmationButtonModule,
  GovBrToggleModule,
  GovBrLoadingModule,
} from '@serpro/ngx-dsgovbr';

@NgModule({
  imports: [
    GovBrAccordionModule,
    GovBrAlertMessagesModule,
    GovBrCardModule,
    GovBrCommonModule,
    GovBrConfirmationsModule,
    GovBrDatePickerModule,
    GovBrFieldsetModule,
    GovBrFileModule,
    GovBrHeaderModule,
    GovBrLocalMessageModule,
    GovBrInputModule,
    GovBrPickListModule,
    GovBrSecondTabModule,
    GovBrSelectModule,
    GovBrSubFieldsetModule,
    GovBrTabModule,
    GovBrTableModule,
    GovBrPaginationModule,
    GovBrTextAreaModule,
    GovBrTitleModule,
    GovBrVerticalTabModule,
    GovBrDirectivesModule,
    GovBrPipesModule,
    GovBrMainLayoutModule,
    GovBrFabPaneModule,
    GovBrConfirmationButtonModule,
    GovBrToggleModule,
    GovBrLoadingModule,
  ],
  exports: [
    GovBrAccordionModule,
    GovBrAlertMessagesModule,
    GovBrCardModule,
    GovBrCommonModule,
    GovBrConfirmationsModule,
    GovBrDatePickerModule,
    GovBrFieldsetModule,
    GovBrFileModule,
    GovBrHeaderModule,
    GovBrLocalMessageModule,
    GovBrInputModule,
    GovBrPickListModule,
    GovBrSecondTabModule,
    GovBrSelectModule,
    GovBrSubFieldsetModule,
    GovBrTabModule,
    GovBrTableModule,
    GovBrPaginationModule,
    GovBrTextAreaModule,
    GovBrTitleModule,
    GovBrVerticalTabModule,
    GovBrDirectivesModule,
    GovBrPipesModule,
    GovBrMainLayoutModule,
    GovBrFabPaneModule,
    GovBrConfirmationButtonModule,
    GovBrToggleModule,
    GovBrLoadingModule,
  ]
})
export class GovBrModule { }
