import { FormataDataHoraExtensoPipe } from "../shared/pipes/formata-DataHora-Extenso.pipe";

export class AcessibilidadeUtil {

  static descrever(textContent: string) {
    const elemento = document.getElementById('liveRegion');
    if (elemento) {
      elemento.textContent = textContent;
    }
    return elemento?.textContent
  }

  static lerMensagem(target: string) {
    setTimeout(() => {
      let inputElement = document.querySelector('br-alert-messages[onlyFor="'+target+'"]') as HTMLElement;
      inputElement.setAttribute('tabindex', '0');
      inputElement.focus();
      setTimeout(() => {
        inputElement.setAttribute('tabindex', 'null');
      }, 500);
    }, 500);
  }

  static getDataHora(dataHora: string): string {
    return FormataDataHoraExtensoPipe.transform(dataHora);
  }

  static getPorCento(texto: string): string {
    return texto.replace(/%/g, ' por cento');
  }

  static getDescricao(texto: string, valor: string): string {
    if (valor != undefined && valor != null && valor != '' && valor != '.') {
      texto = texto + valor;
    } else {
      texto = texto + ' dado não definido';
    }
    return texto.toLowerCase();
  }
}
