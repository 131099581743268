import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pageable } from '../util/pageable-request';
import { LpcoFiltro } from './models/lpco-filtro.model';
import { LpcoLista } from './models/lpco-lista.model';
import { HEADER_SKIP_ERROR_INTERCEPTOR } from '../error/error.headers';
import { DestaqueNCM } from '../gestao-sistema/parametros-indicadores-risco/model/destaque-ncm.model';

@Injectable({
  providedIn: 'root'
})
export class LpcoService {

  resourceUrl = environment.serviceAnaliseUrl;

  lpcoFiltro: LpcoFiltro = new LpcoFiltro;
  lpcoSelecionada: LpcoLista = new LpcoLista();
  lpcoCurrentPage: number = 1;

  constructor(private http: HttpClient) { }

  private construirParametros(page: any): any {
    let params = new HttpParams();
    params = params = params.set('page', page.page ? page.page : '');
    params = params = params.set('size', page.size ? page.size : '');
    params = params = params.set('sort', page.sort ? page.sort : '');
    return params;
  }

  setLpcoFiltro(lpcoFiltro: any) {
    this.lpcoFiltro = lpcoFiltro;
  }

  getLpcoFiltro() {
      return this.lpcoFiltro;
  }

  setLpcoSelecionada(lpcoSelecionada: any) {
    this.lpcoSelecionada = lpcoSelecionada;
}

  getLpcoSelecionada() {
      return this.lpcoSelecionada;
  }

  setLpcoCurrentPage(n: number) {
    this.lpcoCurrentPage = n;
  }

  getLpcoCurrentPage() {
      return this.lpcoCurrentPage;
  }

  obterTodos(filtro: any, pageable: Pageable): Observable<any> {
    const options = filtro ?
        {
            params: this.construirParametros(pageable)
        } : {};

    return this.http.post(this.resourceUrl + '/lpco/listar', filtro, options);
  }

  obterDetalheLpcoNumero(numero: string): Observable<any> {
    return this.http.get(this.resourceUrl + '/lpco/'+numero+'/detalhe');
  }

  obterListaSituacoes(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/situacao');
  }

  obterListaSituacoesNaoFinalisticas(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/situacao/nao-finalisticas');
  }

  obterMunicipios(uf: string): Observable<any>  {
    return this.http.get(this.resourceUrl + '/interveniente/municipio?uf='+uf);
  }

  obterModelos(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/modelo-paubrasil', {
      headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')
      });
  }

  obterNcmsByModelo(codigoModelo: string): Observable<any> {
    return this.http.get(this.resourceUrl + '/modelo/' + codigoModelo + '/ncm', {
      headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')
      });
  }

  obterDestaquesByModeloAndNcm(codigoModelo: string, codigoNcm: string): Observable<DestaqueNCM[]> {
    return this.http.get<DestaqueNCM[]>(this.resourceUrl + '/modelo/' + codigoModelo + '/ncm/' + codigoNcm + '/destaque', {
      headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')
      });
  }

  obterListaPaisImportador() {
    return this.http.get(this.resourceUrl + '/dominio/pais-importador');
  }

  obterListaPaisDestino() {
    return this.http.get(this.resourceUrl + '/dominio/pais-destino');
  }

  obterListaUnidadeRfbDespacho() {
    return this.http.get(this.resourceUrl + '/dominio/unidade-rfb-despacho');
  }

  obterListaUnidadeRfbEmbarque() {
    return this.http.get(this.resourceUrl + '/dominio/unidade-rfb-embarque');
  }

  obterHistoricoSituacoes(numeroLpco: string, pageable: Pageable): Observable<any> {
    const options = numeroLpco ?
        {
            params: this.construirParametros(pageable)
        } : {};
    return this.http.get(this.resourceUrl + '/situacao/lpco/' + numeroLpco, options);
  }

  obterListaDocumentosAnexo(numeroLpco: string): Observable<any> {
    return this.http.get(this.resourceUrl + '/documento/por-lpco/'+numeroLpco);
  }

  downloadDocumentoAnexo(idDocumento: number) {
    return this.http.get(this.resourceUrl + '/documento/download/'+idDocumento, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  listarLpcoParaDistribuicao(filtro: any, pageable: Pageable): Observable<any> {
    const options = filtro ?
        {
            params: this.construirParametros(pageable)
        } : {};

    return this.http.post(this.resourceUrl + '/lpco/para-distribuicao', filtro, options);
  }

}
