import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'formataDecimal'
})
export class FormataDecimalPipe implements PipeTransform {

  transform(valor: number): string {
    if (valor == undefined || valor == null || valor == 0) {
      return '-';
    }
    let valorString: string = valor.toString();

    if (valorString.indexOf('.') > 0) {
      valorString = valorString.replace('.', ',');
    } else {
      valorString = valorString + ',00';
    }
    if (valorString.substring((valorString.indexOf(',') + 2), (valorString.indexOf(',') + 3)) == '') {
      valorString = valorString + '0';
    }
    return valorString;
  }

}
