import {TipoLicencaIbama} from '../../shared/enum/tipo-licenca-ibama.enum';
import { NcmListar } from './ncm-listar.model';

export class LpcoLista {

    public isUsuarioLiderEquipe ?: boolean;
    public selecionada?: boolean = false;
    public codigoPauBrasil?: string;
    public cpfCnpj?: string;
    public dataRegistro?: Date;
    public nomeModelo?: string;
    public nomeModeloResumido?: string;
    public descricaoNcm?: string;
    public licencaIbama?: TipoLicencaIbama;
    public modelo?: string;
    public ncm?: string;
    public nomeNi?: string;
    public numero?: string;
    public situacao?: string;
    public situacaoCodigo?: string;
    public tora?: boolean;
    public peixeMarinho?: boolean;
    public peixeContinental?: boolean;
    public carvao?: boolean;
    public protocoloMontreal?: boolean;
    public modeloNaoIdentificado?: boolean;
    public telaGenerica?: boolean;
    public temMaisNcm?:boolean;
    public ncms?:NcmListar[];
    public temRetificacao?: boolean;
    public temProrrogacao?: boolean;
    public cpfResponsavel?: string;
    public nomeResponsavel?: string;
    public nomeEquipe?: string;
    public podeAnalisar?: boolean;

    constructor(
        isUsuarioLiderEquipe ?: boolean,
        selecionada?: boolean,
        codigoPauBrasil?: string,
        cpfCnpj?: string,
        dataRegistro?: Date,
        nomeModelo?: string,
        nomeModeloResumido?: string,
        descricaoNcm?: string,
        licencaIbama?: TipoLicencaIbama,
        modelo?: string,
        ncm?: string,
        nomeNi?: string,
        numero?: string,
        situacao?: string,
        situacaoCodigo?: string,
        tora?: boolean,
        peixeMarinho?: boolean,
        peixeContinental?: boolean,
        carvao?: boolean,
        protocoloMontreal?: boolean,
        modeloNaoIdentificado?: boolean,
        telaGenerica?: boolean,
        temMaisNcm?:boolean,
        ncms?:NcmListar[],
        temRetificacao?: boolean,
        temProrrogacao?: boolean,
        cpfResponsavel?: string,
        nomeResponsavel?: string,
        nomeEquipe?: string,
        podeAnalisar?: boolean,
    ) {
        this.isUsuarioLiderEquipe = isUsuarioLiderEquipe;
        this.selecionada = selecionada;
        this.codigoPauBrasil = codigoPauBrasil;
        this.cpfCnpj = cpfCnpj;
        this.dataRegistro = dataRegistro;
        this.nomeModelo = nomeModelo;
        this.nomeModeloResumido = nomeModeloResumido;
        this.descricaoNcm = descricaoNcm;
        this.licencaIbama = licencaIbama;
        this.modelo = modelo;
        this.ncm = ncm;
        this.nomeNi = nomeNi;
        this.numero = numero;
        this.situacao = situacao;
        this.situacaoCodigo = situacaoCodigo;
        this.tora = tora;
        this.peixeMarinho = peixeMarinho;
        this.peixeContinental = peixeContinental;
        this.carvao = carvao;
        this.protocoloMontreal = protocoloMontreal;
        this.modeloNaoIdentificado = modeloNaoIdentificado;
        this.telaGenerica = telaGenerica;
        this.temMaisNcm = temMaisNcm;
        this.ncms = ncms;
        this.temRetificacao = temRetificacao;
        this.temProrrogacao = temProrrogacao;
        this.cpfResponsavel = cpfResponsavel;
        this.nomeResponsavel = nomeResponsavel;
        this.nomeEquipe = nomeEquipe;
        this.podeAnalisar = podeAnalisar;
    }

}
