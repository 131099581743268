
export class LpcoFiltro{
    constructor(
        public atribuidasMim?:boolean,
        public emRetificacao?: boolean,
        public prorrogacaoPendente?: boolean,
        public naoDistribuidasEquipe?: boolean,
        public naoDistribuidasServidor?: boolean,
        public numero?: string,
        public dataRegistroInicial?: Date,
        public dataRegistroFinal?: Date,
        public responsavelAnalise?: string,
        public codigoSituacao?: string,
        public codigoModelo?: string,
        public ncm?: string,
        public importadorExportador?: string,
        public uf?: string,
        public municipio?: string,
        public numeroCtf?: string,
        public importador?: string,
        public paisImportador?: string,
        public paisDestino?: string,
        public unidadeRfbDespacho?: string,
        public unidadeRfbEmbarque?: string,
        public dataPrevisaoEmbarqueInicial?: Date,
        public dataPrevisaoEmbarqueFinal?: Date,
        public veiculoVoo?: string,
        public numeroDof?: string,
        public boletoPago?: boolean,
        public boletoVencido?: boolean,
        public boletoPagamentoPendente?: boolean,
        public semBoleto?: boolean,
        public idEquipe?: number,

        public situacoes?: string[],

        public collapsedFiltroBasico: boolean = true,
        public collapsedFiltroAvancado: boolean = true,
    ){
        // this.codigoSituacao=SituacoesPermitidas.PARA_ANALISE;
    }
}
