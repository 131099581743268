import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formataDataHoraExtenso',
})
export class FormataDataHoraExtensoPipe implements PipeTransform {
  static transform: any;

  transform(value: string): string {

    if (value == null || value == '') {
      return '';
    }

    const date = new Date(value);

    const daysOfWeek = ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'];
    const months = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayOfWeek = daysOfWeek[date.getDay()];

    // return `${dayOfWeek}, ${day} de ${month} de ${year}, ${hours} horas e ${minutes} minutos`;
    return `${day} de ${month} de ${year}, ${hours} horas e ${minutes} minutos`;
  }
}

