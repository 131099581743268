import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Application, Menu, User } from '@serpro/ngx-dsgovbr';

import { Roles } from '../security/roles.enum';
import { UserService } from '../security/user.service';

@Component({
  selector: 'app-pagina-inicial',
  templateUrl: './pagina-inicial.component.html',
  styleUrls: ['./pagina-inicial.component.css']
})
export class PaginaInicialComponent implements OnInit, AfterViewInit {

  @ViewChild('sideMenu', { static: false, read: ElementRef }) sideMenu!: ElementRef;

  showScrollToTop = false;
  @Input() showMenu = true;

  usuario: User = {
    name: this.userService.user.nome,
    displayName: this.userService.user.nome,
    email: this.userService.user.email,
    roles: this.userService.user.roles.map(role => ({
      name: role,
      description: role // TODO: verificar onde o ngx-dsgov usa essa description
    })),
  };

  menu: Menu = {
    itens: [
      {
        label: 'Home',
        action: '/home',
        icon: 'fas fa fa-home',
        roleNames: [Roles.INDICADORES_VISUZALIZAR],
        // ariaLabel: 'Home',
        // ariaLabelledby: 'menu-item-home',
        // id: 'menu-item-home',
      },
      {
        label: "Listar LPCO's",
        action: '/lpco',
        icon: 'fas fa-solid fa-list',
        roleNames: [Roles.LPCO_VISUALIZAR, Roles.LPCO_ANALISAR],
      },
      {
        label: 'Gestão do Sistema',
        icon: 'fas fa-solid fa-globe',
        roleNames: [Roles.TAXAS_VISUALIZAR, Roles.TAXAS_EDITAR, Roles.CONFIGURACOES_CTF_VISUALIZAR, Roles.CONFIGURACOES_CTF_EDITAR],
        itens: [
          {
            label: 'Parametrizar taxas',
            action: 'parametrizar/taxas',
            icon: 'fas fa-solid fa-barcode',
          },
          {
            label: 'Configurar Atividades CTF',
            action: 'configurar/ctfmodelo',
            icon: 'fas fa-cog',
          },
          {
            label: 'Parametrizar Indicador',
            action: 'gestaoderiscos/paramindicador',
            icon: 'fas fa-percent',
          },
        ]
      },
      {
        label: 'Gestão de Equipes',
        action: '/equipes',
        icon: 'fas fa-solid fa-users',
        roleNames: [Roles.SERVIDORES_VISUALIZAR, Roles.SERVIDORES_EDITAR, Roles.EQUIPES_VISUALIZAR, Roles.EQUIPES_EDITAR],
      },
      {
        label: "Distribuição de LPCO's",
        action: '/distribuir',
        icon: 'fas fa-project-diagram',
        roleNames: [Roles.DISTRIBUIR_EQUIPE, Roles.DISTRIBUIR_SERVIDOR],
      },
      {
        label: 'Relatórios',
        icon: 'fas fa-solid fa-file',
        roleNames: [Roles.EXPORTAR_RELATORIO],
        itens: [
          {
            label: 'Gerar Dados LPCO\'s',
            action: 'relatorios/gerar',
            icon: 'fas fa-solid fa-file-csv',
          },
          {
            label: 'Exportar Dados LPCO\'s',
            action: 'relatorios/exportar',
            icon: 'fas fa-solid fa-file-export',
          },
        ]
      },
    ]
  };

  app: Application = {
    menu: this.menu,
    version: 'PLATAFORMA DE ANUÊNCIA ÚNICA',
    name: 'PAU-BRASIL',
  };

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    sessionStorage.removeItem('equipeFiltro');
    sessionStorage.removeItem('filtros');
    sessionStorage.removeItem('distribuicaoFiltro');
  }

  rolarParaTopo() {
    window.scroll(0, 0);
  }

  @HostListener('window:scroll')
  onScroll() {
    this.showScrollToTop = window.pageYOffset > 0;
  }

  onMenuContainerClick() {
    sessionStorage.removeItem('equipeFiltro');
    sessionStorage.removeItem('filtros');
    if (this.app.menu.overlay) {
      this.showMenu = false;
    }
  }

  getDataHora() {
    return new Date();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkMenuAvailability();
      const menuItems = document.querySelectorAll('.menu-label');
      menuItems.forEach(item => {
        if(item.textContent == "Listar LPCO's") {
          item.setAttribute('aria-label', 'Listar L P C ÓS');
        }
        if(item.textContent == "Distribuição de LPCO's") {
          item.setAttribute('aria-label', 'Distribuição de L P C ÓS');
        }
        if(item.textContent == "Gerar Dados LPCO's") {
          item.setAttribute('aria-label', 'Gerar Dados L P C ÓS');
        }
        if(item.textContent == "Exportar Dados LPCO's") {
          item.setAttribute('aria-label', 'Exportar Dados L P C ÓS');
        }
      });
    }, 0);
  }

  checkMenuAvailability(): void {
    const maxAttempts = 10;
    let attempts = 0;

    const interval = setInterval(() => {
      if (this.sideMenu && this.sideMenu.nativeElement) {
        this.removeTitles();
        clearInterval(interval);
        this.cdr.detectChanges(); // Força a detecção de mudanças
      } else {
        attempts++;
        if (attempts >= maxAttempts) {
          clearInterval(interval);
          console.error('Menu não encontrado após várias tentativas.');
        }
      }
    }, 500);
  }

  removeTitles(): void {
    const menuItems = this.sideMenu.nativeElement.querySelectorAll('[title]');
    menuItems.forEach((item: HTMLElement) => {
      this.renderer.removeAttribute(item, 'title');
    });
  }
}
