import { Mensagem } from "../lpco/models/mensagem-options.models";

export class IbamaUtil {

  constructor() {}

  static removerCaracteres(valor: any) {
    valor = valor !== undefined && valor !== null  ? valor.replace(/[^a-z0-9]/gi, '') : '';
    return valor;
  }

  static trocarPontoPorVirgula(valor: any) {
    valor = valor !== undefined && valor !== null  ? valor.replace('.', ',') : '';
    return valor;
  }

  static smoothScrollTo(endX: number, endY: number, duration: number) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time: number, from: number, distance: number, duration: number) => {
      if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
      return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60); // 60 fps
  }

  static adicionarDiasData(data: Date, dias: number) {
    var dataRetorno = new Date(data);
    dataRetorno.setDate(dataRetorno.getDate() + dias);
    return dataRetorno;
  }

  static subtrairDiasData(data: Date, dias: number) {
    var dataRetorno = new Date(data);
    dataRetorno.setDate(dataRetorno.getDate() - dias);
    return dataRetorno;
  }

  static formatarData(data: Date) {
    var dataRetorno = data;
    return dataRetorno.getDate() + '/' + (dataRetorno.getMonth()+1) + '/' + dataRetorno.getFullYear();
  }

  static formatarDataAMD(data: Date) {
    var dataRetorno = data;

    let mes: string = '';

    if (dataRetorno.getMonth().toString().length == 1) {
      mes = '0' +(dataRetorno.getMonth()+1).toString();
    } else {
      mes = (dataRetorno.getMonth()+1).toString();
    }

    return dataRetorno.getFullYear() + '/' + mes + '/' + dataRetorno.getDate();
  }

  static formatarAmdToMda(valor: string) {
    var vetor = valor.split('-');
    return vetor[2] + '-' + vetor[1] + '-' + vetor[0];
  }

  static formatarAmdToDma(valor: string) {
    var vetor = valor.split('-');
    return vetor[1] + '-' + vetor[2] + '-' + vetor[0];
  }

  static extrairUrl(valor: string) {
    var url1 = valor.substring(valor.indexOf('http'));
    var fim = url1.indexOf(' ');
    return url1.substring(0, fim);
  }

  static formataCpfCnpj(value: string) {
    if (value == null || value == '') {
      return "";
    }

    if (isNaN(value as any)) {
      return value;
    }

    value = IbamaUtil.removerCaracteres(value.toString());

    if (value.length == 11) {
      value = value.substr(0, 3) + '.' + value.substr(3, 3) + '.' + value.substr(6, 3) + '-' + value.substr(9, 2);

    } else if (value.length == 14) {
      value = value.substr(0, 2) + '.' + value.substr(2, 3) + '.' + value.substr(5, 3) + '/' + value.substr(8, 4) + '-' + value.substr(12, 2);
    }

    return value;
  }

  static converterData(dataString: Date): string {
    const date = new Date(dataString);
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const ano = date.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }
  

  static associaveisGestor(situacao: string): boolean {
    let associaveis: string[] = [
      'EM_ANALISE',
      'EM_VERIFICACAO',
      'EM_INSPECAO',
      'EM_EXIGENCIA',
      'RESPOSTA_EXIGENCIA',
      'DEFERIDO',
      'INDEFERIDO',
      'CANCELADO',
      'VENCIDO',
      'ANULADO_REVOGADO'
    ];
    return associaveis.includes(situacao);
  }

  static associaveis(situacao: string): boolean {
    let associaveis: string[] = [
      'EM_ANALISE',
      'EM_VERIFICACAO',
      'EM_INSPECAO',
      'EM_EXIGENCIA',
      'RESPOSTA_EXIGENCIA',
    ];
    return associaveis.includes(situacao);
  }

}
