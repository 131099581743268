import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {

  transform(value: string): any {
    if (value == null) {
      return "";
    }

    value = value.trim();

    return value.substr(0, 5) + '-' + value.substr(5, 3) ;

  }

}
