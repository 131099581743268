import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ncm'
})
export class NcmPipe implements PipeTransform {

  transform(value: string): any {
    if (value == null) {
      return "";
    }

    value = value.toString();

    return value.substr(0, 4) + '.' + value.substr(4, 2) + '.' + value.substr(6, 2) ;

  }

}
