import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formataDataHora'
})
export class FormataDataHoraPipe implements PipeTransform {
  
  transform(date: Date): string {
    if(!date) {
      return date;
    }
    let formato = "DD/MM/yyyy  HH:MM";
    let dataRetorno: moment.Moment = moment(date, formato);
    return dataRetorno.format(formato);
  }

}
