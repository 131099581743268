import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RelatorioAnuentesComponent } from './relatorio-anuentes/relatorio-anuentes.component';
import { AuthGuard } from './security/auth-guard.service';
import { Roles } from './security/roles.enum';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home'
      },
      {
        path: 'lpco',
        data: { breadcrumb: "Listar Lpco's", role: [Roles.LPCO_VISUALIZAR, Roles.LPCO_ANALISAR] },
        // loadChildren: () => import('./lpco-distribuicao-module/lpco-distribuicao.module').then(m => m.LpcoDistribuicaoModule)
        loadChildren: () => import('./lpco/lpco.module').then(m => m.LpcoModule)
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { breadcrumb: 'Home', role: [Roles.INDICADORES_VISUZALIZAR] }
      },
      {
        path: 'configurar',
        data: { breadcrumb: 'Gestão do Sistema', role: [Roles.TAXAS_VISUALIZAR, Roles.TAXAS_EDITAR, Roles.CONFIGURACOES_CTF_VISUALIZAR, Roles.CONFIGURACOES_CTF_EDITAR] },
        loadChildren: () => import('./gestao-sistema/gestao-sistema.module').then(m => m.GestaoSistemaModule)
      },
      {
        path: 'parametrizar',
        data: { breadcrumb: 'Gestão do Sistema', role: [Roles.TAXAS_VISUALIZAR, Roles.TAXAS_EDITAR, Roles.CONFIGURACOES_CTF_VISUALIZAR, Roles.CONFIGURACOES_CTF_EDITAR] },
        loadChildren: () => import('./gestao-sistema/parametrizacao/parametrizacao.module').then(m => m.ParametrizacaoModule)
      },
      {
        path: 'gestaoderiscos',
        data: { breadcrumb: 'Gestão do Sistema', role: [Roles.LPCO_VISUALIZAR] },
        loadChildren: () => import('./gestao-sistema/parametros-indicadores-risco/parametro-risco.module').then(m => m.ParametroRiscoModule)
      },
      {
        path: 'equipes',
        data: { breadcrumb: 'Gestão de Equipes', role: [Roles.EQUIPES_VISUALIZAR, Roles.EQUIPES_EDITAR]  },
        loadChildren: () => import('./equipes/equipes.module').then(m => m.EquipesModule)
      },
      {
        path: 'distribuir',
        data: { breadcrumb: "Distribuição de LPCO's",},
        loadChildren: () => import('./distribuicao-lpco/distribuicao.module').then(m => m.DistribuicaoModule)
      },
      {
        path: 'relatorios',
        data: { breadcrumb: 'Relatórios', role: [Roles.EXPORTAR_RELATORIO] },
        loadChildren: () => import('./relatorio-lpco/relatorio-lpco.module').then(m => m.RelatorioLpcoModule)
      },
    ],
  },
  {
    path: 'login-success',
    pathMatch: 'full',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
