import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fomartStringDataHora'
})
export class FomartStringDataHoraPipe implements PipeTransform {

  transform(date: string): string {
    if (date == null || date == '') {
      return date;
    }
    return date.substring(8,10)+'/'+ date.substring(5,7) + '/' + date.substring(0,4) + ' ' + date.substring(11,16);
  }

}
