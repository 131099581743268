import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ParametroRiscoComponent } from "./parametro-risco/parametro-risco.component";
import { Roles } from "src/app/security/roles.enum";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'paramindicador',
  },
  {
    path: 'paramindicador',
    component: ParametroRiscoComponent,
    data: {
      breadcrumb: 'Configurar parâmetros do indicador de alerta',
      role: [Roles.LPCO_VISUALIZAR]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParametroRiscoRoutingModule { }
