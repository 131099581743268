import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  GovBrAlertMessagesModule,
  GovBrCardModule,
  GovBrCommonModule,
  GovBrFieldsetModule,
  GovBrInputModule,
  GovBrLoadingModule,
  GovBrSelectModule,
  GovBrTableModule,
  GovBrTabModule,
} from '@serpro/ngx-dsgovbr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { SecurityModule } from 'src/app/security/security.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ParametroRiscoRoutingModule } from './parametro-risco-routing.module';
import { ParametroRiscoComponent } from './parametro-risco/parametro-risco.component';

@NgModule({
    declarations: [
      ParametroRiscoComponent,
    ],
    imports: [
      ParametroRiscoRoutingModule,
      CommonModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      SecurityModule,
      GovBrLoadingModule,
      GovBrCommonModule,
      GovBrCardModule,
      GovBrAlertMessagesModule,
      GovBrFieldsetModule,
      GovBrInputModule,
      GovBrSelectModule,
      GovBrTableModule,
      GovBrTabModule,
      NgxSliderModule,
      NgxMaskModule.forRoot(),
    ],
    exports: [
    ],
    providers:[
      { provide: LOCALE_ID, useValue: 'pt-BR' },
    ]
  })
  export class ParametroRiscoModule {
    constructor(private localeService: BsLocaleService) {
      localeService.use('pt-br');
   }
  }
