import { Directive, ElementRef, Host, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderDirective } from './order.directive';

const SORT_ASC_CLASSES = ['datatable-icon-up', 'sort-asc'];
const SORT_DESC_CLASSES = ['datatable-icon-down', 'sort-desc'];
const UNSET_CLASS = 'datatable-icon-sort-unset';

@Directive({
  selector: '[appOrderBy]'
})
export class OrderByDirective implements OnInit, OnDestroy {
  constructor(
    @Host() private orderDirective: OrderDirective,
    private el: ElementRef
  ) { }

  /** Passar o nome da propriedade do objeto. Se for uma lista de tipos primitivos, passar '_self' */
  @Input('appOrderBy')
  property?: string;

  private arrow?: HTMLSpanElement;

  private subscription?: Subscription;

  ngOnInit(): void {
    this.appendArrow();

    this.subscription = this.orderDirective.change$.subscribe(order => {
      if (order.property === this.property) {
        this.arrow!.classList.remove(UNSET_CLASS);

        if (order.reverse) {
          this.arrow!.classList.add(...SORT_DESC_CLASSES);
          this.arrow!.classList.remove(...SORT_ASC_CLASSES);
        } else {
          this.arrow!.classList.add(...SORT_ASC_CLASSES);
          this.arrow!.classList.remove(...SORT_DESC_CLASSES);
        }
      } else {
        this.arrow!.classList.remove(...SORT_ASC_CLASSES);
        this.arrow!.classList.remove(...SORT_DESC_CLASSES);
        this.arrow!.classList.add(UNSET_CLASS);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click')
  click() {
    if (this.property) {
      this.orderDirective.orderBy = this.property;
    }
  }

  private appendArrow() {
    const span = document.createElement('span');
    span.classList.add('sort-btn', 'datatable-icon-sort-unset');
    this.arrow = span;
    this.el.nativeElement.appendChild(span);
  }
}

