import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fomartStringDate'
})
export class FomartStringDatePipe implements PipeTransform {

  transform(date: any): string {
    if (date == null || date == '') {
      return date;
    }
    return date.substring(8,10)+'/'+ date.substring(5,7) + '/' + date.substring(0,4);
  }

}
