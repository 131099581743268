import { Directive, Input, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Directive({
  selector: '[appOrder]'
})
export class OrderDirective implements OnInit {
  private _order?: Order;
  private changeSubject = new ReplaySubject<Order>(1);
  readonly change$ = this.changeSubject.asObservable();

  @Input('appOrder')
  list?: any[];

  @Input('defaultOrder')
  defaultOrder?: Order;


  ngOnInit(): void {
    if (this.defaultOrder) {
      this._order = this.defaultOrder;
      this.changeSubject.next(this._order);
    }
  }

  set orderBy(property: string) {
    this._order = {
        property: property,
        reverse:
            this._order && this._order.property === property
                ? !this._order.reverse
                : (this._order?.reverse || false),
    };
    this.changeSubject.next(this._order);
    this.sort();
  }

  private sort() {
    if (!this.list || !this._order) return;

    this.list.sort((first, second) => {
      if (typeof first === 'object') {
        if (!this._order?.property) {
          console.error(`OrderDirective - propriedade não informada.`)
          return 0;
        }

        const firstValue = getValue(first, this._order?.property);
        const secondValue = getValue(second, this._order?.property);
        return compare(firstValue, secondValue, this._order?.reverse);
      } else { // se não é um objeto, compara o próprio valor
        return compare(first, second, this._order?.reverse);
      }
    });

  }
}

function compare(value1: any, value2: any, reverse = false) {
  if  (value1 == value2) return 0;

  if (reverse) {
    return value1 > value2 ? -1 : 1;
  } else {
    return value1 > value2 ? 1 : -1;
  }
}

function getValue(obj: any, property: string) {
  if (!obj) return null;

  if (property.indexOf('.') >= 0) { // trata objetos aninhados (obj.obj2.propriedade, etc)
    const props = property.split('.');
    return props.reduce((acc, current) => {
      return acc[current];
    }, obj);
  }

  return obj[property];
}

interface Order {
  property: string;
  reverse: boolean;
}
